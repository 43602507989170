import React, { useEffect, useRef, useState } from 'react'
import { TbCameraPlus } from "react-icons/tb";
import classnames from 'classnames';
import Cropper from 'react-easy-crop';
import { FaCheck, FaTimes } from "react-icons/fa"
import { getCroppedBlob } from './imageCropperUtils';
import './imageCropper.css'
import BtnOutlineSecondary from '../BtnOutlineSecondary/BtnOutlineSecondary';
import { Button, Col } from 'react-bootstrap';

export default function ImageCropper({ className, onCrop }) {
    const [imageToCrop, setImageToCrop] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const inputFileRef = useRef(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [zoom, setZoom] = useState(1);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const onChangeImage = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.addEventListener("load", () => {
                setImageToCrop(reader.result);
            });
        }
        event.target.value = ''
    }
    const handleCropImage = async () => {
        const blob = await getCroppedBlob(imageToCrop, croppedArea);
        const url = URL.createObjectURL(blob);
        const img = {
            src: url,
            blob
        };
        setCroppedImage(img);
        setImageToCrop(null)
    }
    const handleNewFile = () => {
        inputFileRef.current.click();
    }
    const successCrop = () => {
        onCrop(croppedImage)
    }
    return (
        <div className={classnames("image-cropper-container", { [className]: className })}>
            <input type="file" className='d-none' ref={inputFileRef} onChange={onChangeImage} />
            {
                croppedImage ?
                    < figure >
                        <img src={croppedImage.src} />
                        <figcaption className='d-flex justify'>
                            <Col xs = {5}>
                                <Button  variant="outline-danger" onClick={() => setCroppedImage(null)}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs = {5}>
                                <BtnOutlineSecondary clickHandler = {successCrop}>
                                    Confirmar
                                </BtnOutlineSecondary>
                            </Col>
                        </figcaption>
                    </figure>
                    : (
                        !imageToCrop ?
                            <button className='image-cropper-trigger' onClick={handleNewFile}>
                                <TbCameraPlus size={50} color='rgba(63, 62, 62, 0.9)' />
                                <div>Nueva foto</div>
                            </button>
                            :
                            <>
                                <Cropper
                                    objectFit='contain'
                                    image={imageToCrop}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={3 / 2}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    cropSize={{ width: 298, height: 298 }}
                                    onZoomChange={setZoom} />
                                <div className="controls">
                                    <button className='cancel' onClick={() => setImageToCrop(false)}>
                                        <FaTimes />
                                    </button>
                                    <input
                                        type="range"
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e) => setZoom(e.target.value)}
                                        className="zoom-range"
                                    />
                                    <button className='confirm' onClick={handleCropImage}>
                                        <FaCheck />
                                    </button>
                                </div>
                            </>
                    )
            }
        </div>
    )
}
