import React, { useContext, useEffect, useState } from "react";
import "./newProduct.css";
import Product from "../../models/Product";
import NewProductCategories from "../../components/NewProduct/NewProductCategories/NewProductCategories";
import ProductBasicData from "../../components/NewProduct/NewProductBasicData/NewProductBasicData";
import NewProductDescription from "../../components/NewProduct/NewProductDescription/NewProductDescription";
import NewProductVariantOptions from "../../components/NewProduct/NewProductVariants/NewProductVariants";
import NewProductVariantSettings from "../../components/NewProduct/NewProductVariantsSettings/NewProductVariantsSettings";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import PreviousDraftsModal from "../../components/PreviousDraftsModal/PreviousDraftsModal";
import NewProductImage from "../../components/NewProduct/NewProductImage/NewProductImage";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ProductVariantSwitch from "../../components/NewProductVariantSwitch/NewProductVariantSwitch";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import _products from "../../services/product.service";
import _productVariants from "../../services/productVariant.service";

function NewProductScreen() {
  const [newProduct, setNewProduct] = useState(Product.createEmptyProduct());
  const params  = useParams();
  const [variants, setVariants] = useState([]);
  const [previousDrafts, setPreviousDrafts] = useState([])
  const [variantOptions, setVariantOptions] = useState([]);
  const [variantOptionValues, setVariantOptionValues] = useState([]);
  const { state } = useContext(Store);
  const { userInfo } = state;
  //#region BOOLEAN FLAGS STATES FOR EACH STEP  
  const [loading, setLoading] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [basicDataIsCompleted, setBasicDataIsCompleted] = useState(false);
  const [imageIsCompleted, setImageIsCompleted] = useState(false);
  const [variantDataIsCompleted, setVariantDataIsCompleted] = useState(false);
  const [variantsAreValid, setVariantsAreValid] = useState(false);  
  const completeBasicData = () => setBasicDataIsCompleted(true);
  const cancelCompleteBasicData = () => setBasicDataIsCompleted(false);
  const navigate = useNavigate();
  //#endregion
  //#region SETTERS SHORTCUTS
  const setProductKey = (key, value) => {
    const productCopy = Product.create(newProduct);
    productCopy[key] = value;
    setNewProduct(productCopy);
  }
  const setImage = (value) => {
    setProductKey("image", value);
  }
  const setHasVariants = async(hasVariants) => {
      await updateDraft({
        hasVariants
      }, () => setProductKey('hasVariants',hasVariants));
  }
  const loadVariantData = (variants, options, optionValues) => {
    setVariants(variantsToObj(variants));
    setVariantOptions(options);
    setVariantOptionValues(optionValues);
    setVariantDataIsCompleted(true)
  }

  const variantsToObj = (variantList) => {
    const variantObj = {};
    variantList.forEach((v) => {
      variantObj[v._id] = v;
    });
    return variantObj;
  }

  const backToOptionsHandler = () => {
    setVariantDataIsCompleted(false);
  };
  //#endregion
  //#region REQUESTS
  const saveDraft = async () => {
    if (!newProduct._id) {
      try {
        const { data } = await _products.createDraft(newProduct);
        setNewProduct(Product.create(data));
        completeBasicData();
      } catch (ex) {
        console.error(ex);
        toast.error(getError(ex));
      }
    } else {
      completeBasicData();
    }
  }
  useEffect(() => {
    if(params.id) {
      setLoading(true);
      fetchProduct(params.id);
    } else {
      getPreviousDrafts();
    }
  }, [params]);

  useEffect(() => {
    if(Object.keys(variants)) {
      setVariantsAreValid(doVariantsHaveAtLeastOneImage());
    }
  }, [variants]);

  const doVariantsHaveAtLeastOneImage  =  () =>  {
    return Object.keys(variants).some(variantId => variants[variantId].image);
  }

  const handleFetchProductError = (err) => {
    console.error(err);
    toast.error(getError(err));
  }
  const fetchProduct = async (id) => {
    try {
      const {data} = await _products.getWithVariants(id); 
      loadProductData(data);  
    } catch(ex) {
      handleFetchProductError(ex)
    }
  }
  const getPreviousDrafts = async () => {
    try {
      const { data } = await _products.getDrafts();
      if (data.length) {
        setOpenDraftModal(true);
        setPreviousDrafts(data);
      }
    } catch (ex) {
      console.error(ex);
      toast.error("No se pudo obtener los borradores de producto")
    }
  }
  const loadProductData = (data) => {
    const { variants, options, values, product } = data;
    loadVariantData(variants, options, values);
    const productToLoad = Product.create(product);
    setNewProduct(productToLoad);
    setBasicDataIsCompleted(productToLoad.isBasicDataValid());
    setImageIsCompleted(productToLoad.image && productToLoad.image.fileLink);
    setVariantDataIsCompleted(!!variants.length);
    setLoading(false);
  }
  const selectPreviousDraft = async (draft) => {
    try {
      const { data } = await _productVariants.getAllVariantData(draft._id);
      loadProductData({...data, product: draft});
    } catch (ex) {
      console.error(ex);
      toast.error("No se pudo obtener las variantes del producto");
    } finally {
      setOpenDraftModal(false);
    }
  }

  const saveProductImage = async (image) => {
    await updateDraft({
      image
    }, () => {
      setImage(image);
      toast.success("Imagen guardada");
      setImageIsCompleted(true);
    })
  }
  const saveData = () => {
    if(newProduct._id) {
      saveBasicData();
    } else{
      saveDraft();
    }
  };

  const saveBasicData = async () => {
    const { name, slug, image, brand, category, htmlDescription, description
      , price, countInStock, minStockToConfirm, hasVariants, active,
      highlightedProduct, priceRange } = newProduct
    await updateDraft({
      name, slug, image, brand, category, htmlDescription, description
      , price, countInStock, minStockToConfirm, hasVariants, active,
      highlightedProduct, priceRange
    }, completeBasicData)
  }
  
  const updateDraft = async (data, onSuccess) => {
    if(data && typeof onSuccess === 'function') {
      try {
        await _products.editDraft(newProduct._id, data);
        onSuccess();
      } catch (ex) {
        console.error(ex);
        toast.error("No se pudo actualizar el borrador.");
      }
    } else {
      console.error("Missing or incorrect type of params.");
      toast.error("No se pudo actualizar el borrador");
    }
  }
  const saveProduct = async () => {
    let data,msg;
    if(params.id) {
      await _products.edit(params.id, newProduct);
      navigate("/AdminScreen/AdminProducts/search");
      toast.success("Producto actualizado.");
    } else {
      await updateDraft({
        isDraft: false,
        htmlDescription: newProduct.htmlDescription
      }, () => {
        navigate("/AdminScreen/AdminProducts/search")
        toast.success("Producto creado.");
      });
    }
    if(params.id) {
      data = newProduct;
      msg = "Producto actualizado";
    } else {
      data = {
        isDraft: false,
        htmlDescription: newProduct.htmlDescription
      }
      msg = "Producto creado.";
    }
  }
  //#endregion 
  const commonProps = {
    isStep : !params.id,
    product: newProduct,
    token: userInfo.token,
    onChange: setProductKey
  }
  return (
    <div id="new-product-container" className="admin-con">
      <PreviousDraftsModal hide={() => setOpenDraftModal(false)} selectPreviousDraft={selectPreviousDraft} show={openDraftModal}
        token={userInfo.token} drafts={previousDrafts} setDrafts={setPreviousDrafts} />
      { !loading ? 
        <>
          <div id="product-crud-title" className="d-flex col-xl-7 col-lg-8 col-md-9 col-10">
            <div className="col-6 d-flex justify-content-around align-items-center">
              <h1 className="section-title">
                {params.id ? "Editar " : "Crear "}producto
              </h1>
            </div>
            <div className="col-3 h-50">
              <img src={"/product-shelf.svg"} className="img-fluid" alt="product-icon" />
            </div>
          </div>
          <NewProductCategories disabled={basicDataIsCompleted} {...commonProps}/>
          <ProductBasicData onComplete={saveData} disabled={basicDataIsCompleted} onCancel={cancelCompleteBasicData} {...commonProps}/>
          {basicDataIsCompleted && <>
            <ProductVariantSwitch {...commonProps} onChange={setHasVariants} />
            {newProduct.hasVariants ?
              <>
                {variantDataIsCompleted ?
                  <NewProductVariantSettings variantsAreValid = {variantsAreValid} token={userInfo.token} toggleUsage={backToOptionsHandler} variants={variants}
                    variantOptions={variantOptions} optionValues={variantOptionValues} setVariants = {setVariants} /> :
                  <NewProductVariantOptions {...commonProps} onComplete={loadVariantData} options={variantOptions}
                    setOptions={setVariantOptions} optionValues={variantOptionValues} setOptionValues={setVariantOptionValues}
                  />
                }
              </>
              :
              <NewProductImage {...commonProps} goBack={() => setImageIsCompleted(false)}  nextStep={() => setImageIsCompleted(true)}
                 disabled={imageIsCompleted} onSuccess={saveProductImage} />}
            {
              (newProduct.hasVariants ? variantsAreValid : imageIsCompleted) &&
              <NewProductDescription {...commonProps} />
            }
            <div className="d-flex w-100">
              <Button onClick={saveProduct}
                disabled={newProduct.hasVariants ? !variantsAreValid : !imageIsCompleted}>
                {params.id ? "Actualizar " : "Crear "} producto
              </Button>
            </div>
          </>
          }
        </>
        : 
        <LoadingBox/>
      }
    </div>
  );
}
export default NewProductScreen;
