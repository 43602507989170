export const getCroppedBlob = async (imageSrc, crop) => {
	if (!crop || !imageSrc) {
		return;
	}
	const canvas = await getCroppedImg(imageSrc, crop);
	const blob = await new Promise(resolve => canvas.toBlob(resolve));
	return blob;
};
const createImage = (url) => {
	const img = new Image();
	img.src = url;
	return img;
};
function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
	try {
		const image = createImage(imageSrc);
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		const maxSize = Math.max(image.width, image.height);
		const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

		// set each dimensions to double largest dimension to allow for a safe area for the
		// image to rotate in without being clipped by canvas context
		canvas.width = safeArea;
		canvas.height = safeArea;

		// translate canvas context to a central location on image to allow rotating around the center.
		ctx.translate(safeArea / 2, safeArea / 2);
		ctx.rotate(getRadianAngle(rotation));
		ctx.translate(-safeArea / 2, -safeArea / 2);

		// draw rotated image and store data.
		ctx.drawImage(
			image,
			safeArea / 2 - image.width * 0.5,
			safeArea / 2 - image.height * 0.5
		);

		const data = ctx.getImageData(0, 0, safeArea, safeArea);

		// set canvas width to final desired crop size - this will clear existing context
		canvas.width = pixelCrop.width;
		canvas.height = pixelCrop.height;

		// paste generated rotate image with correct offsets for x,y crop values.
		ctx.putImageData(
			data,
			0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
			0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
		);

		// As Base64 string
		// return canvas.toDataURL("image/jpeg");
		return canvas;
	}
	catch (ex) {
		console.error(ex);
	}
}