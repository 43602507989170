import React, { useContext, useEffect, useState } from 'react';
import "./CheckoutNavigator.css";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import CheckoutStepper from '../CheckoutStepper/CheckoutStepper';
import { addAlphaToHex } from '../../utils';
import { IoChevronForwardCircleOutline, IoChevronBackCircleOutline } from "react-icons/io5";
import { Store } from '../../Store';



export default function CheckoutNavigator({ previousStepHandler, nextStepHandler, backButtonText = "Volver", forwardButtonText = "Continuar",
 activeStep, nextStepDisabled = false}) {
  const { state } = useContext(Store);
  const {usesShipping} = state;
  const [buttonColor, setButtonColor] = useState('');
  useEffect(() => {
    const dimmedButtonColor = addAlphaToHex(getComputedStyle(document.documentElement)
      .getPropertyValue('--secondaryColor').replace("#", ""), 0.5);
    setButtonColor(dimmedButtonColor);
  }, []);

  return <div id="checkout-navigator">
    <div className="w-100 d-flex align-items-center desktop">
      {<Button onClick={previousStepHandler} disabled = {!previousStepHandler} className={classnames('d-sm-flex d-none', { 'invisible': !previousStepHandler })}>
        <IoChevronBackCircleOutline />
        {backButtonText}
      </Button>}
      <CheckoutStepper activeStep={activeStep} usesShipping={usesShipping}/>
      <Button onClick={nextStepHandler} disabled = {nextStepDisabled} className={classnames('d-sm-flex d-none', { 'invisible': !nextStepHandler })}>
        {forwardButtonText}
        <IoChevronForwardCircleOutline />
      </Button>
    </div>
    <div className={classnames('w-100 d-flex d-sm-none d-block align-items-center',
     { 'justify-content-center' : !previousStepHandler || !nextStepHandler  , 'justify-content-around': previousStepHandler && nextStepHandler })}>
      {previousStepHandler && <Button onClick = {previousStepHandler}>
        <IoChevronBackCircleOutline />
        {backButtonText}
      </Button>}
      { nextStepHandler && 
        <Button onClick={nextStepHandler} disabled = {nextStepDisabled} >
        {forwardButtonText}
        <IoChevronForwardCircleOutline />
      </Button>
      }
    </div>
  </div>
}
