import React, { useReducer, useContext, useEffect, useState } from 'react';
import { getError } from '../../utils';
import { toast } from 'react-toastify';
import { Table, Card, Button } from 'react-bootstrap';
import {
	FaPlusCircle,
	FaRegTrashAlt,
	FaEdit 
} from 'react-icons/fa';
import { BsSlashCircle } from "react-icons/bs";
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { Store } from '../../Store';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Pagination from '../../components/ParamPagination/ParamPagination.jsx';
import _discountCodes from '../../services/discountcodes.service.js';
import CreateDiscountCodeModal from '../CreateDiscountCodeModal/CreateDiscountCodeModal.jsx';
import moment from 'moment'; 
import './DiscountCodes.css'; 
import EditDiscountCodeModal from '../CreateDiscountCodeModal/EditDiscountCodeModal.jsx';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			discountCodes: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return {
			...state,
			loadingUpdate: false,
			discountCodes: state.discountCodes.map((code) =>
				code._id === action.payload._id ? action.payload : code
			),
		};
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };
	default:
		return state;
	}
};

function DiscountCodes() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const [
		{ loadingFetch, errorFetch, discountCodes, successDelete, itemQuantity, loadingUpdate },
		dispatch,
	] = useReducer(reducer, {
		discountCodes: [],
		loadingFetch: true,
		loadingUpdate: false,
		errorFetch: '',
		itemQuantity: 0,
	});

	const [siblingCount,setSiblingCount] = useState(1);
	window.addEventListener('resize', (e) => {
		if(window.innerWidth < 400) {
			setSiblingCount(0);
		} else {
			setSiblingCount(1);
		}
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);

	// Calculate the index for pagination
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentPosts = discountCodes.slice(indexOfFirstItem, indexOfLastItem);

	// Handle pagination
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const couponIsActive = async (coupon) => {
		const today = moment().startOf('day');
	
		const dateFrom = coupon.dateFrom ? moment(coupon.dateFrom).startOf('day') : null;
		const dateTo = coupon.dateTo ? moment(coupon.dateTo).endOf('day') : null;
	
		if (dateFrom && dateTo) {
			coupon.active = today.isBetween(dateFrom, dateTo, null, '[]');
		} else if (dateFrom) {
			coupon.active = today.isSameOrAfter(dateFrom);
		} else {
			coupon.active = false;
		}
	
		return coupon;
	};
	
	// Fetch discount codes from the API
	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
	
		try {
			const result = await _discountCodes.list();

			const updatedDiscountCodes = await Promise.all(
				result.data.map(async(discountCode) => await couponIsActive(discountCode))
			);
			dispatch({ type: 'FETCH_SUCCESS', payload: updatedDiscountCodes });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			console.error(error);
			toast.error('Error obteniendo los códigos de descuento.');
		}
	};
	



	// Handle discount code deletion
	const deleteHandler = async (discountCode) => {
		if (window.confirm('Are you sure to delete?')) {
			try {
				await _discountCodes.delete(discountCode._id);
				toast.success('Código de descuento eliminado correctamente');
				dispatch({ type: 'DELETE_SUCCESS' });
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(err));
			}
		}
	};

	const disableCoupon = async (discountCode) => {
		dispatch({ type: 'UPDATE_REQUEST'});
		let updatedCode;
		try {
			if(couponIsActive(discountCode)){
				const result = await _discountCodes.inactiveCode(discountCode._id);
				updatedCode = { ...result.data.discountCode, active: false };
				dispatch({ type: 'UPDATE_SUCCESS', payload: updatedCode });
			} 
			toast.success(`Código de descuento ${!discountCode.active ? 'activado' : 'desactivado'} correctamente.`);
		} catch (error) {
			dispatch({ type: 'UPDATE_FAIL' });
			console.error(error);
			toast.error('Error al actualizar el código de descuento.');
		}
	};

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
			fetchData();
		} else {
			fetchData(); 
		}
	}, [successDelete, userInfo, loadingUpdate]);

	// Modal state management
	const [modalShow, setModalShow] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);
	const [selectedDiscountCode, setSelectedDiscountCode] = useState(null);


	const handleClose = (modalType) => {
    if (modalType === 'edit') {
        setSelectedDiscountCode(null);
        setEditModalShow(false);
    } else {
        setModalShow(false);
    }
};

	const handleShow = (modalType, discountCode = null) => {
    if (modalType === 'edit') {
        setSelectedDiscountCode(discountCode);
        setEditModalShow(true);
    } else {
        setModalShow(true);
    }
};

	const handleCreateDiscountCode = async (discountCodeData) => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await _discountCodes.create(discountCodeData);
			// Add the new discount code to the list
			dispatch({
				type: 'FETCH_SUCCESS',
				payload: [...discountCodes, result.data],
			});
			handleClose();
			toast.success('Código de descuento creado correctamente.');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			console.error(error);
			toast.error('Error al crear el código de descuento.');
		}
	};


	const handleUpdateDiscountCode = async (discountCodeData) => {
    dispatch({ type: 'UPDATE_REQUEST' });
    try {
        const result = await _discountCodes.update(discountCodeData._id, discountCodeData);
        
        dispatch({
            type: 'UPDATE_SUCCESS',
            payload: result.data.discountCode,
        });
        handleClose('edit');
        toast.success('Código de descuento actualizado correctamente.');
    } catch (error) {
        dispatch({ type: 'UPDATE_FAIL', payload: error.message });
        console.error(error);
        toast.error('Error al actualizar el código de descuento.');
    }
};


	// Handle window resize event
	useEffect(() => {
		const handleResize = () => {
			setSiblingCount(window.innerWidth < 400 ? 0 : 1);
		};

		handleResize(); // Initial check

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
	}, []);

	return (
		<div>
			<Helmet>
				<title>Códigos de Descuento</title>
			</Helmet>
			<div className="container">
				{loadingFetch ? (
					<LoadingBox />
				) : errorFetch ? (
					<MessageBox variant="danger">{errorFetch}</MessageBox>
				) : (
					<div>
						<div className="row adminProdBtnRow align-items-center justify-content-end">
							<div className="col-lg-2 col-sm-12 ">
								<Button onClick={() => handleShow()}>
									<FaPlusCircle /> Agregar Código
								</Button>
								<CreateDiscountCodeModal
									show={modalShow}
									handleClose={()=>handleClose()}
									handleCreateDiscountCode={handleCreateDiscountCode}
								/>
							</div>
						</div>
						<Card>
							<Table responsive hover size="sm">
								<thead>
									<tr>
										<th className="text-center">Código</th>
										<th className="text-center">% Descuento</th>
										<th className="text-center">Fecha de Inicio</th>
										<th className="text-center">Fecha de Fin</th>
										<th className="text-center"></th>
									</tr>
								</thead>
								<tbody>
									{currentPosts.map((discountCode, i) => (
										<tr key={i}>
											<td className="text-center">{discountCode.code}</td>
											<td className="text-center">{discountCode.discountPercent}</td>
											<td className="text-center">{discountCode.dateFrom ? moment(discountCode.dateFrom).format('DD/MM/YYYY'): '-'}</td>
											<td className="text-center">{discountCode.dateTo ? moment(discountCode.dateTo).format('DD/MM/YYYY'): '-'}</td>
											<td className="text-center">
												<Button onClick={() => deleteHandler(discountCode)} className='mx-1'>
													<FaRegTrashAlt /> 
												</Button>
									
											<Button onClick={() => handleShow("edit", discountCode)}>
        <FaEdit /> 
    </Button>

<Button onClick={() => disableCoupon(discountCode)} className='mx-1' disabled={!discountCode.active}>
													<BsSlashCircle />
												</Button>
												<EditDiscountCodeModal
														show={editModalShow}
														discountCode={selectedDiscountCode}
														handleClose={()=>handleClose('edit')}
														handleUpdateDiscountCode={handleUpdateDiscountCode}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card>
					</div>
				)}
				<div className="admin-pagination-container">
					<Pagination
						siblingCount={siblingCount}
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={itemsPerPage}
					/>
				</div>
			</div>
		</div>
	);
}

export default DiscountCodes;
