import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './CreateDiscountCodeModal.css'; // Adjust the CSS file name if needed
import { toast } from 'react-toastify'; 
import moment from 'moment'; // Ensure you have moment installed

function CreateDiscountCodeModal({ show, handleClose, handleCreateDiscountCode }) {
    const [code, setCode] = useState('');
    const [discountPercent, setDiscountPercent] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [active, setActive] = useState(true);
    const [errors, setErrors] = useState({});

    const resetModalState = () => {
        setCode('');
        setDiscountPercent('');
        setDateFrom('');
        setDateTo('');
        setActive(false);
        setErrors({});
    };

    const handleModalClose = () => {
        resetModalState();
        handleClose();
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleDiscountPercentChange = (e) => {
        setDiscountPercent(e.target.value);
    };

    const handleDateFromChange = (e) => {
        setDateFrom(e.target.value);
    };

    const handleDateToChange = (e) => {
        setDateTo(e.target.value);
    };
    const validateDiscount = () => {
        const errors = {};
        if (discountPercent < 1 || discountPercent > 100) {
            errors.discountPercent = 'El porcentaje de descuento debe estar entre 1 y 100';
        }
        return errors;
    }
    const validateDates = () => {
        const errors = {};
        const today = moment().startOf('day');
        const from = moment(dateFrom).startOf('day');
        const to = moment(dateTo).startOf('day');
    
        // Validate code
        if (code.length !== 6 || !/^[a-zA-Z0-9]+$/.test(code)) {
            errors.code = 'El código debe tener 6 caracteres alfanuméricos';
        }
    
        // Validate dateFrom and dateTo
        if (dateFrom && !from.isValid()) {
            errors.dateFrom = 'Fecha de inicio inválida';
        }
    
        if (dateTo && !to.isValid()) {
            errors.dateTo = 'Fecha de fin inválida';
        }
    
        if (dateFrom && dateTo && from.isAfter(to)) {
            errors.dateRange = 'La fecha de inicio no puede ser posterior a la fecha de fin';
        }
    
        if (dateFrom && dateTo && today.isBetween(from, to)) {
            setActive(true);
        } else {
            setActive(false);
        }
    
        return errors;
    };
    const validateErrors = () => ({...validateDates(), ...validateDiscount()});

    const handleSubmit = () => {
        const errors = validateErrors();
        if (Object.keys(errors).length === 0) {
            const discountCodeData = {
                code,
                discountPercent: parseFloat(discountPercent),
                dateFrom,
                dateTo,
                active,
            };

            handleCreateDiscountCode(discountCodeData);
            handleModalClose();
        } else {
            setErrors(errors);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered dialogClassName='create-discount-code-modal'>
            <Modal.Header closeButton>
                <Modal.Title className='text-center w-100'>Crear Código de Descuento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <Form.Group controlId="formCode">
                        <Form.Label>Código</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el código del descuento"
                            value={code}
                            onChange={handleCodeChange}
                        />
                        {errors.code && <Form.Text className="text-danger">{errors.code}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId="formDiscountPercent">
                        <Form.Label>Porcentaje de Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            min={1}
                            placeholder="Ingrese el porcentaje de descuento"
                            value={discountPercent}
                            onChange={handleDiscountPercentChange}
                        />
                        {errors.discountPercent && <Form.Text className="text-danger">{errors.discountPercent}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId="formDateFrom">
                        <Form.Label>Fecha de Inicio</Form.Label>
                        <Form.Control
                            type="date"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                        />
                        {errors.dateFrom && <Form.Text className="text-danger">{errors.dateFrom}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId="formDateTo">
                        <Form.Label>Fecha de Fin</Form.Label>
                        <Form.Control
                            type="date"
                            value={dateTo}
                            onChange={handleDateToChange}
                        />
                        {errors.dateTo && <Form.Text className="text-danger">{errors.dateTo}</Form.Text>}
                        {errors.dateRange && <Form.Text className="text-danger">{errors.dateRange}</Form.Text>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateDiscountCodeModal;
