import axios from 'axios';
import { PERMISSIONS, ROLES, SCOPES } from './permission-utils/scopes';
import axiosInstance from './restClient';
import { customHistory } from './CustomBrowserRouter';
import {toast} from 'react-toastify';



export const safelyParseJSON = (item, fallback = null) => {
  try {
    return item ? JSON.parse(item) : fallback;
  } catch (error) {
    console.error('JSON parsing error:', error);
    return fallback;
  }
}


export const getError = (error) => {
	return error.response && error.response.data.message
		? error.response.data.message
		: error.message;
};
export const showRequestError = (error) => {
	console.error(error);
	toast.error(getError(error));
}; 
export const getStatus = (error) => {
	return error.response && error.response.status
		? error.response.status
		: error.message;
};
export const makeVariantName = (variant) => {
	const options = variant.options.map(({option, value}) => {
		return `${option.name}: ${value.value}`;
	});
	return options.join(', ');
};
/**
 * @param {String} hex Six digit hexadecimal color
 * @param {Number} alpha Number between 0 and 1
 */
export const addAlphaToHex = (hex, alpha) => {
	let convertedColor = hex;
	if (hex.length === 6 && alpha > 0 && alpha <= 1) {
		const hexValues = hex.match(/.{1,2}/g);
		const rgbArray = [
			parseInt(hexValues[0], 16),
			parseInt(hexValues[1], 16),
			parseInt(hexValues[2], 16)
		];
		convertedColor = `rgb(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]},${alpha})`;
	}
	return convertedColor;
};

export const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100;
export const regexEmail =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const whatsappBaseUrl = 'https://api.whatsapp.com/send?phone=';

export function hasPermission (role, fn, scopes,field) {
	let allowed = false;
	if(ROLES[role] && PERMISSIONS[role]) {
		const rolePerms = PERMISSIONS[role];
		const fnPerms = rolePerms[fn];
		const perms = field ? fnPerms.fields[field] : fnPerms.pagePermissions;
    
		if(perms) {
			allowed = perms.includes(SCOPES.canWrite);
			if(!allowed) {
				allowed =  scopes
					.every(scope => perms.includes(scope));
			}
		}
	}
	return allowed;
}
/**
 * 
 * @param {string} url url to send request
 * @param {string} token token to use for authorization 
 * @param {axios.Method} method  http action for method
 */
export function axiosRequest(url, token, method, data = {}) {
	const reqConfig = {
		url,
		headers: { authorization: `Bearer ${token}` },
		method,
		data 
	};
	return axios(reqConfig);
}
/**
 * 
 * @param {string} url url to send request
 * @param {string} token token to use for authorization 
 */
export function axiosGet(url, token) {
	return axiosRequest(url,token,'GET');
}
/**
 * 
 * @param {string} url url to send request
 * @param {string} token token to use for authorization 
 * @param {*} token body to send 
 */
export function axiosPatch(url, token, data) {
	return axiosRequest(url,token,'PATCH',data);
}
/**
 * 
 * @param {Array} data array of data to make a new copy of
 * @param {function} predicate callback to find index of element to remove
 * @returns new array of data without element that matches predicate (if found)
 */
export function removeElementFromNewData(data = [],predicate) {
	const newData = [...data];
	if(Array.isArray(data) && typeof predicate === 'function') {
		const indexToRemove = newData.findIndex(predicate);
		if(indexToRemove >= 0) {
			console.info('indexToRemove',indexToRemove);
			newData.splice(indexToRemove,1);
		}
		return newData;
	}
}
export function getToken() {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	const token = userInfo ? userInfo.token : null;
	return token;
}
export async function refreshToken() {
	const refresTkn = localStorage.getItem('refreshToken');
	const response = await axiosInstance.post('/auth/refreshToken', { refresTkn });
	const { newRefreshToken, tokenn } = response.data;
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	userInfo.token = tokenn;
	localStorage.removeItem('userInfo');
	localStorage.setItem('userInfo', JSON.stringify(userInfo));
	if (newRefreshToken) {
		localStorage.removeItem('refreshToken');
		localStorage.setItem('refreshToken', newRefreshToken);
	}
	return tokenn;
}

export const logoutHandler = async (ctxDispatch) => {
	const refreshToken = localStorage.getItem('refreshToken');
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (userInfo && refreshToken) {
		await axios.post('/api/auth/logout', {
			refreshToken,
			id: userInfo._id,
		});
	}
	ctxDispatch({ type: 'USER_SIGNOUT' });
};

export const getFilterUrl = (fields) => {
	const params = new URLSearchParams();
	for (const fieldKey in fields) {
		const value = fields[fieldKey];
		if(value) {
			params.append(fieldKey, value);
		}
	}
	return params.toString();
};
export const getParamsObj = (searchParams) => {
	const searchParamsKeys = searchParams.keys();
	const query = {};
	for (const key of searchParamsKeys) {
		query[key] = searchParams.get(key);
	}
	return query;
};
/**
 * 
 * @param {string} str
 */
export const toCamelCase = (str) => {
return (str.charAt(0).toLowerCase() + str.slice(1)).replace(/\s/g, "");
}
/**
 * 
 * @param {*} str 
 */
export const toPascalSeparatedCase = (str) => {
	const words = str.split(/(?=[A-Z])/);
	const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
	return capitalizedWords.join(' ');
}
export const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 6,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};
export const PRICES = [
	{
		name: '$1 a $50',
		value: '1-50',
	},
	{
		name: '$51 a $200',
		value: '51-200',
	},
	{
		name: '$201 a $1000',
		value: '201-1000',
	},
];  
