import React, { useEffect, useState } from 'react';
import './EditStockPriceModel.css';
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../LoadingBox/LoadingBox';
import flapService from '../../services/flap.service';
import { toast } from 'react-toastify';

export default function EditStockPriceModal({ show, product, closeHandler, updateType = '' }) {
	const [newPrice, setNewPrice] = useState('');
	const [newStock, setNewStock] = useState('');
	const [newMinStock, setNewMinStock] = useState('');

	useEffect(() => {
		if (product) {
			setNewPrice(product.price.toString());
			setNewStock(product.countInStock.toString());
			setNewMinStock(product.minStockToConfirm?.toString());
		}
	}, [product]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let flapCreated = false;
		try {
			if ((updateType === 'price' || updateType === '') && newPrice && product.price.toString() !== newPrice) {
				await flapService.create("price", product.price, newPrice, "product", product.name, product._id);
				flapCreated = true;
			}
			if ((updateType === 'stock' || updateType === '') && newStock && product.countInStock.toString() !== newStock) {
				await flapService.create("countInStock", product.countInStock, newStock, "product", product.name, product._id);
				flapCreated = true;
			}
			if ((updateType === 'minStock' || updateType === '') && newMinStock && product.minStockToConfirm?.toString() !== newMinStock) {
				await flapService.create("minStockToConfirm", product.minStockToConfirm, newMinStock, "product", product.name, product._id);
				flapCreated = true;
			}
			if (flapCreated) {
				toast.success("Solicitud de cambio creada con éxito.");
			} else {
				toast.info("No hubo cambios para crear un FLAP");
			}
			closeHandler();
		} catch (error) {
			toast.error("Error al crear FLAP");
		}
	};

	const getTitle = () => {
		switch (updateType) {
		case 'price':
			return 'Modificar Precio';
		case 'stock':
			return 'Modificar Stock';
		case 'minStock':
			return 'Modificar Stock Mínimo';
		default:
			return 'Modificar Precio/Stock/Stock Mínimo';
		}
	};

	return (
		<Modal dialogClassName = "edit-stock-price-modal" show={show} size='md' onHide={closeHandler} centered>
			<Modal.Header closeButton>
				<Modal.Title>{getTitle()}</Modal.Title>
			</Modal.Header>
			{product ? (
				<div className="productScreenContainer">
					<Helmet>
						<title>{product.name.toUpperCase()}</title>
					</Helmet>
					<form onSubmit={handleSubmit}>
						<Row>
							<Col>
								<div className="form-group">
									<label>Producto: {product.name}</label>
								</div>
								{(updateType === 'price' || updateType === '') && (
									<>
										<div className="form-group">
											<label>Precio Actual: ${product.price}</label>
										</div>
										<div className="form-group">
											<label>Nuevo Precio:</label>
											<input
												type="text"
												className="line-input"
												value={newPrice}
												onChange={(e) => setNewPrice(e.target.value)}
											/>
										</div>
									</>
								)}
								{(updateType === 'stock' || updateType === '') && (
									<>
										<div className="form-group">
											<label>Stock Actual: {product.countInStock}</label>
										</div>
										<div className="form-group">
											<label>Nuevo Stock:</label>
											<input
												type="text"
												className="line-input"
												value={newStock}
												onChange={(e) => setNewStock(e.target.value)}
											/>
										</div>
									</>
								)}
								{(updateType === 'minStock' || updateType === '') && (
									<>
										<div className="form-group">
											<label>Stock Mínimo Actual: {product.minStockToConfirm}</label>
										</div>
										<div className="form-group">
											<label>Nuevo Stock Mínimo:</label>
											<input
												type="text"
												className="line-input"
												value={newMinStock}
												onChange={(e) => setNewMinStock(e.target.value)}
											/>
										</div>
									</>
								)}
								<div className="buttons">
									<button type="button" className="cancel" onClick={closeHandler}>Cancelar</button>
									<button type="submit" className="confirm">Confirmar</button>
								</div>
							</Col>
						</Row>
					</form>
				</div>
			) : (
				<div className="loading-box-modal">
					<LoadingBox size="lg" />
				</div>
			)}
		</Modal>
	);
}
