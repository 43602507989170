import React, {  useEffect, useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import MessageBox from "../../components/MessageBox/MessageBox.jsx";
import { getError } from "../../utils";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Card } from "react-bootstrap";
import _orders from "../../services/order.service.js";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, orders: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function OrderHistoryScreen() {
  const navigate = useNavigate();

  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const {data} = await _orders.listOwn();
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FETCH_FAIL",
        payload: getError(error),
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Mis Pedidos</title>
      </Helmet>

      <h1>Mis Pedidos</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Card>
          <Table responsive hover size="sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>Fecha</th>
                <th>total</th>
                <th>Pago</th>
                <th>Confirmado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>{order.totalPrice.toFixed(2)}</td>
                  <td>
                    {order.isPaid || order.mercadoPagoStatus === "approved"
                      ? order.paidAt.substring(0, 10)
                      : "No"}
                  </td>
                  <td>
                    {order.isDelivered
                      ? order.deliveredAt.substring(0, 10)
                      : "No"}
                  </td>
                  <td>
                    <Button
                      type="button"
                      variant="light"
                      onClick={() => {
                        navigate(`/order/${order._id}`);
                      }}
                    >
                      Detalles
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </div>
  );
}

export default OrderHistoryScreen;
