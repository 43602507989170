import React, { useEffect, useState } from 'react'
import './LoginPrompt.css';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaRegUser } from "react-icons/fa";
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import { addAlphaToHex } from '../../utils';


export default function LoginPrompt({ className, buttonCaption = "Ingresar" , caption = 'Iniciar Sesión', showPassword, showForgotPassword, submitHandler }) {

    const [inputColor, setInputColor] = useState('');
    const [buttonColor, setButtonColor] = useState('');
    const [passwordInputType , setPasswordInputType] = useState('password');

    useEffect(() => {
        const dimmedInputColor = addAlphaToHex(getComputedStyle(document.documentElement)
            .getPropertyValue('--primaryColor').replace("#", ""), 0.2);
        const dimmedButtonColor = addAlphaToHex(getComputedStyle(document.documentElement)
            .getPropertyValue('--secondaryColor').replace("#", ""), 0.45);
        setInputColor(dimmedInputColor);
        setButtonColor(dimmedButtonColor);
    }, []);
    const submitData = (e) => {
        e.preventDefault();
        console.info(e);
        const {email, password} = e.target;
        submitHandler(email.value, showPassword ? password.value : null);
    }
    const togglePasswordVisibility = () => setPasswordInputType(type => type === 'password' ? 'text' : 'password');
    return (
        <Form className={classnames('login-prompt', { [className]: className })} onSubmit={submitData}>
            <div className="header">
            <h2 className='text-center'>
                {caption}
            </h2>
            </div>
            <div className='body justify-content-center align-items-center d-flex flex-wrap'>
                <InputGroup className='my-2 mt-3'>
                    <InputGroup.Text style={{ backgroundColor: inputColor }}> <FaRegUser /></InputGroup.Text>
                    <Form.Control name='email' style={{ backgroundColor: inputColor }} type="email" placeholder="Correo electrónico" />
                </InputGroup>
                {showPassword && <InputGroup className='my-2'>
                    <InputGroup.Text style={{ backgroundColor: inputColor }} >
                        <button type='button' className='bg-transparent p-0 m-0' onClick={togglePasswordVisibility} >
                            {passwordInputType === 'password' ?
                                <MdOutlineLock />
                                : <MdOutlineLockOpen />
                            }
                        </button>
                    </InputGroup.Text>
                    <Form.Control name='password' style={{ backgroundColor: inputColor }} type={passwordInputType} placeholder="Contraseña" />
                </InputGroup>}

            </div>
            <div className="footer">
            <button type="submit" className='mt-1' style={{ backgroundColor: buttonColor }}>
                    {buttonCaption}
                </button>
            </div>
        </Form>
    )
}
