import React, { useState } from 'react';
import {Dropdown, Button, DropdownButton } from 'react-bootstrap';
import './StatusDropdown.css';
import Span from '../Span/Span';
import StatusModal from '../StatusModal/StatusModal';

const statusTranslations = {
  'All': 'Todos',
  'Awaiting Confirmation': 'Pendiente de confirmación',
  'Pending Payment': 'Pendiente de pago',
  'Pending Deliver': 'Pendiente de entrega',
  'Completed': 'Entregado',
  'Cancelled': 'Cancelado',
  'Paid': 'Pago'
};

function StatusDropdown({ status, dropdownItems, isButton, item=null}) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

	const translatedStatus = statusTranslations[status] || status;
  const statusClass = status.toLowerCase().replace(/\s+/g, '-');	
	if (dropdownItems.length === 0) {
    return <Span variant={statusClass}>{translatedStatus}</Span>;
	}

	return (
		isButton ? (
      <>
      <Button
        variant="link"
        className={`status-button-${statusClass} status-button`}
        onClick={handleShow}
      >
          {translatedStatus}
					</Button>
      <StatusModal
        show={show}
        handleClose={handleClose}
        status={status}
        order={item}
        options={dropdownItems}
      />
    </>
    ) :
    (

		<DropdownButton
			id="dropdown-basic-button"
			title={translatedStatus}
			variant={statusClass}
			className={`status-dropdown-${statusClass} status-dropdown`}
			align="end"
		>
			{dropdownItems.map((item, index) => (
				<Dropdown.Item
					key={index}
					eventKey={item.eventKey}
					onClick={item.onClick}
				>
					{item.label}
				</Dropdown.Item>
			))}
		</DropdownButton>
    )
	);
}

export default StatusDropdown;
