import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import InputGroup from "react-bootstrap/InputGroup";
import _auth from "../../services/auth.service";

export default function SignInScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectInUrl = searchParams.get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const {data} = await _auth.login(email,password);
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
			localStorage.setItem('refreshToken', data.refreshToken);
      navigate(redirect || "/");
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
    if(!userInfo) {
      ctxDispatch({ type: "USER_SIGNOUT" });
    }
  }, [navigate, redirect, userInfo]);

  return (
    <Container className="small-container">
      <Helmet>
        <title>Iniciar sesión</title>
      </Helmet>
      <h1 className="my-3">Iniciar Sesión</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="email"
            required
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          ></Form.Control>
        </Form.Group>
        <Form.Label>Contraseña</Form.Label>
        <InputGroup className="mb-3 p-0">
          <Form.Control
            id="password"
            type={passwordType}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="btn btn--secondary"
            className="eyeButton"
            id="button-addon2"
            onClick={togglePassword}
          >
            {passwordType === "password" ? (
              <BsFillEyeSlashFill />
            ) : (
              <BsFillEyeFill />
            )}
          </Button>
        </InputGroup>
        <div className="mb-3">
          <Link to={`/reset-password`}>Olvide mi contraseña</Link>
        </div>
        <div className="mb-3">
          <Button type="submit">Iniciar Sesion</Button>
        </div>
        <div className="mb-3">
          Nuevo cliente?{" "}
          <Link to={`/signup?redirect=${redirect}`}>Crea una cuenta</Link>
        </div>
      </Form>
    </Container>
  );
}
