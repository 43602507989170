import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, {
	useContext,
	useLayoutEffect,
	useState,
	useReducer,
	useRef,
} from 'react';
import { Store } from '../../Store';
import { LinkContainer } from 'react-router-bootstrap';
import '../../App.css';
import './Navbar.css';
import { BsFacebook, BsFillGearFill, BsInstagram, BsWhatsapp } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../SearchBoxOverlay/SearchBoxOverlay';
import { useEffect } from 'react';
import Avatar from 'react-avatar';
import NavbarToggler from '../NavbarToggler/NavbarToggler';
import { RxCross1 } from 'react-icons/rx';
import ClientNavbarOptions from '../ClientNavbarOptions/ClientNavbarOptions';
import _auth from '../../services/auth.service';
import _settings from '../../services/settings.service';
import _faq from '../../services/faq.service';
import SideCart from '../SideCart/SideCart';
import { logoutHandler } from '../../utils';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, GotaSettings: action.payload, loading: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function Navbarr() {

	const [{ loading, GotaSettings }, dispatch] = useReducer(reducer, {
		GotaSettings: {},
		loading: true,
		error: '',
	});
	const [showSideCart , setShowSideCart] = useState(false);
	const [imageURL, setImageURL] = useState();
	const [companyName, setCompanyName] = useState('');
	const [whatsappUrl, setWhatsappUrl] = useState('');
	const [facebookUrl, setFacebookUrl] = useState('');
	const [deliveryMapUrl, setDeliveryMapUrl] = useState('');
	const [googleMapLocationUrl, setGoogleMapLocationUrl] = useState('');
	const [instagramUrl, setInstagramUrl] = useState('');
	const [showOverlay, setShowOverlay] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [questions, setQuestions] = useState([]);
	const collapse = () => setExpanded(false);
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart, userInfo } = state;
	const location = useLocation();
	const route = location.pathname;

	const openCart = () => {
		if(!route.includes('checkout')) {
			ctxDispatch({ type: 'OPEN_CART' });
		}
	};

	const navigate = useNavigate();
	const [expanded, setExpanded] = useState(false);


	useLayoutEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth > 992) {
				setExpanded(false);
			}
		});
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const { data } = await _settings.getPublicData();
				const res = await _faq.list();
				setQuestions(res.data);
				setImageURL(data.image.fileLink);
				setCompanyName(data.companyName);
				setWhatsappUrl(data.whatsappUrl);
				setFacebookUrl(data.facebookUrl);
				setInstagramUrl(data.instagramUrl);
				setGoogleMapLocationUrl(data.googleMapLocationUrl);
				setDeliveryMapUrl(data.deliveryMapUrl);

				dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, []);

	const handleSearchToggle = (show) => {
		setShowSearch(show);
	};

	const signout = async () => {
		await logoutHandler(ctxDispatch);
	};

	const handleExpand = () => {
		setExpanded(!expanded);
		setShowOverlay(false);
	};



	useEffect(() => {
		setShowOverlay(false);
		setExpanded(false);
	}, [location]);

	if (route.includes('AdminScreen')) return null;

	return (
		<div>
			<ToastContainer position="bottom-center" limit={1}></ToastContainer>
			<header>
				<Navbar
					id="client-navbar"
					expanded={expanded}
					className="container-fluid justify-content-between px-1"
					expand="lg container-fluid "
				>
					<div className="col-1 d-lg-none">
						<NavbarToggler onClick={handleExpand} />
					</div>
					<div className="d-flex justify-content-center h-100 align-items-center col-6 col-lg-1">
						<Navbar.Brand>
							<Link className="h-100 d-block" to="/">
								<img src={imageURL} className = "img-fluid h-100" alt={`${companyName} Logo`} />
							</Link>
						</Navbar.Brand>
					</div>
					<Modal backdropClassName="collapsable-navbar-backdrop" show={expanded} dialogClassName="collapsable-navbar-extended">
						<Row className="justify-content-end mt-3 w-100">
							<button className="col-md-1 col-sm-2 col-3 exit-menu" onClick={collapse}>
								<RxCross1 />
							</button>
						</Row>
						<ClientNavbarOptions questions = {questions} googleMapLocationUrl = {googleMapLocationUrl} deliveryMapUrl = {deliveryMapUrl} facebookUrl={facebookUrl} instagramUrl={instagramUrl}
							whatsappUrl={whatsappUrl} collapse={collapse} />
					</Modal>
					<div className="d-lg-block d-none col-lg-6">
						<ClientNavbarOptions questions = {questions}googleMapLocationUrl = {googleMapLocationUrl} deliveryMapUrl = {deliveryMapUrl} facebookUrl={facebookUrl} instagramUrl={instagramUrl}
							whatsappUrl={whatsappUrl} collapse={collapse}  />
					</div>
					<div className="col-1 col-lg-5">
						<SideCart/>
						<div className="row ">
							<div className="row d-none d-lg-block pe-0 mt-2">
								<ul className="social-links pb-2">
									{facebookUrl && <li>
										{' '}
										<a className="nav-link" href={facebookUrl}>
											<BsFacebook color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>}
									{instagramUrl && <li>
										{' '}
										<a className="nav-link" href={instagramUrl}>
											<BsInstagram
												color="var(--IconsColor)"
												fontSize="1.5rem"
											/>
										</a>
									</li>}
									{whatsappUrl && <li>
										<a className="nav-link" href={whatsappUrl}>
											<BsWhatsapp color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>}
								</ul>
							</div>
							<div className="right-navbar-container gx-4">
								<button
									onClick={() => handleSearchToggle(!showSearch)}
									className={'btn-primary-search'}
								>
									<i className="fas fa-search"></i>
								</button>
								<button className="d-flex" onClick={openCart}>
									<i className="fa fa-shopping-cart" aria-hidden="true"></i>
									{cart.cartItems.length > 0 && (
										<Badge pill bg="danger" className="align-self-end d-block">
											{cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
										</Badge>
									)}
								</button>
								{userInfo ? (
									<NavDropdown
										className={showSideCart ? 'invisible' : ''}
										title={
											<Avatar
												fgColor="var(--navFontColor)"
												size="50px"
												textSizeRatio={2}
												color="transparent"
												name={userInfo.name}
											/>
										}
										id="basic-nav-dropdown"
									>
										<LinkContainer to="/profile">
											<NavDropdown.Item>Mi Perfil</NavDropdown.Item>
										</LinkContainer>
										<LinkContainer to="/orderhistory">
											<NavDropdown.Item>Mis Pedidos</NavDropdown.Item>
										</LinkContainer>
										<LinkContainer to="/addresses">
											<NavDropdown.Item>Mis Direcciones</NavDropdown.Item>
										</LinkContainer>
										<NavDropdown.Divider />
										<NavDropdown.Item onClick={signout}>
											{' '}
											<li className="rightlinks">
												{' '}
												<Link
													className="nav-dropdown-exit dropdown-item"
													to="signin"
												>
													<i
														className="fa fa-sign-out "
														aria-hidden="true"
													></i>
													<span>Salir</span>
												</Link>
											</li>
										</NavDropdown.Item>
									</NavDropdown>
								) : (
									<li className="rightlinks ms-2">
										{' '}
										<Link className="nav-link" to="signin">
											<i className="fa fa-user" aria-hidden="true"></i>
											<span className="d-none d-lg-block">Ingresar</span>
										</Link>
									</li>
								)}
							</div>
						</div>
					</div>
					<SearchComponent
						showSearch={showSearch}
						handleSearchToggle={handleSearchToggle}
					/>
				</Navbar>
			</header>
		</div>
	);
}

export default Navbarr;
