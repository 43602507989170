import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';
import ImageCropper from '../../../ImageCropper/ImageCropper';
import { makeVariantName } from '../../../../utils';
import ButtonSecondaryTranslucidHover from '../../../ButtonSecondaryTranslucid/ButtonSecondaryTranslucidHover';
import { Button } from 'react-bootstrap';
import _productVariant from "../../../../services/productVariant.service";

export default function VariantConfiguration({ variant, setVariantValue, onSuccess }) {
    const saveImage = async (image) => {
        const file = image.blob;
        const formData = new FormData();
        let r = Math.random().toString(36).substring(7);
        formData.append("file", file, makeVariantName(variant).replaceAll(" ") + r);
        try {
            const response = await _productVariant.uploadImage(variant._id, formData);
            toast.success("Imagen guardada.")
            setVariantValue(variant._id, "image", response.data, true);
        } catch (err) {
            console.error(err);
            toast.error("Error cargando imagen.");
        }
    };
    const deleteImage = async () => {
        try {
            await _productVariant.deleteImage(variant.image._id);
            setVariantValue(variant._id, "image", null, true);
            toast.success("Imagen eliminada");
        } catch (err) {
            console.error(err);
            toast.error("Error eliminando imagen.");
        }
    };
    const updateVariants = async () => {
        try {
            await _productVariant
                .edit(variant._id, variant.countInStock, variant.active, variant.price)
            toast.success("Variante actualizada.");
            onSuccess();
        } catch (err) {
            console.error(err);
            toast.error("Error actualizando variante.");
        }
    };
    if (!variant || !setVariantValue) {
        console.error("You must pass a variant to bind with the inputs and a setter to change the variant value ");
        return;
    }
    return (
        <Row>
            <Col md={6}>
                {variant.image ?
                    < figure >
                        <img src={variant.image.fileLink} />
                    </figure>
                    :
                    <ImageCropper onCrop={saveImage} />
                }
            </Col>
            <Col md={6} className='row variant-input-row p-1'>
                <Col xs={12}>
                    <FloatingLabel label="Precio">
                        <Form.Control placeholder='Nombre' value={variant.price} onChange={(e) => setVariantValue(variant._id, 'price', e.target.value)} />
                    </FloatingLabel>
                </Col>
                <Col xs={12}>
                    <FloatingLabel label="Stock">
                        <Form.Control placeholder='Nombre' value={variant.countInStock} onChange={(e) => setVariantValue(variant._id, 'countInStock', e.target.value)} />
                    </FloatingLabel>
                </Col>
                <Col xs={12}>
                    <FloatingLabel label="Activo">
                        <Form.Check placeholder='Nombre' value={variant.active} onChange={(e) => setVariantValue(variant._id, 'active', e.target.checked)} />
                    </FloatingLabel>
                </Col>
            </Col>
            <div className='d-flex justify-content-around mt-4'>
                <ButtonSecondaryTranslucidHover disabled={!variant.image} onClick={deleteImage}>
                    Eliminar foto
                </ButtonSecondaryTranslucidHover>
                <Button onClick={updateVariants}>
                    Guardar
                </Button>
            </div>
        </Row>
    )
}
