import React, { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { addAlphaToHex } from '../../../utils';
import {round2, getSubtotal, getTotalItems, getDiscountAmount, getDiscountedTotal} from '../../../pricesUtils'
import { Store } from '../../../Store';
import CartItem from '../../../components/SideCart/CartItem/CartItem';
import GoBackBtn from '../../../components/GoBackButton/GoBackBtn';
import _settings from '../../../services/settings.service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';


export default function CartSummary({ className, submitHandler, canDeleteItems, canChangeQuantity, hasBackButton, discountPercent}) {
	const navigate = useNavigate();
	const [buttonColor, setButtonColor] = useState('');
	const { state, dispatch: cxtDispatch } = useContext(Store);
	const {
		cart: { cartItems },
	} = state;
	const removeItemHandler = (item) => {
		cxtDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
	};

   
	useEffect(() => {
		const dimmedButtonColor = addAlphaToHex(getComputedStyle(document.documentElement)
			.getPropertyValue('--primaryColor').replace('#', ''), 0.45);
		setButtonColor(dimmedButtonColor);
	}, []);

	return (
		<Card className={classnames('cart-summary',{[className]: className})} style={{maxHeight:'750px'}}>
			<Card.Header>
				<h2>Resumen del Pedido</h2>
			</Card.Header>
			<Card.Body style={{overflowY: 'auto'}}>
				{cartItems.toReversed().map((item) => (
					<CartItem canDeleteItem = {canDeleteItems}  canChangeQuantity = {canChangeQuantity} 
						key={item._id} item={item} removeItemHandler={removeItemHandler} />
				))}
			</Card.Body>
			<Card.Footer className='p-3'>
				<div>

					<div className="d-flex justify-content-end align-items-right">
						<h2 className="fw-light">
     {discountPercent>0 ? 'Subtotal' : 'Total'}  ({getTotalItems(cartItems)} producto{getTotalItems(cartItems) > 1 ? 's' : ''}) : 
							<span className={`order-total ${discountPercent > 0 ? 'text-decoration-line-through' : ''}`}>
      ${getSubtotal(cartItems)}
							</span>
						</h2>
					
					</div>

					{discountPercent > 0 && (
						<div className="d-flex justify-content-end mt-2">
							<p className="fw-light text-success">
                Descuento ({discountPercent}%):
								<span className='discount-amount'> -${getDiscountAmount(cartItems, discountPercent)}</span>
							</p>
						</div>

                        
					)}

{discountPercent > 0 && (
    <>
        <div className='border'></div>
    	<div className="d-flex justify-content-end align-items-right mt-2">
							<h2 className="fw-bold">
								<span className='order-total'>Total: ${getDiscountedTotal(cartItems, discountPercent)}</span>
							</h2>
                            </div>
    </>
						)}
					{hasBackButton &&
                        <GoBackBtn />
					}
					{submitHandler && <button
						style={{ backgroundColor: buttonColor }}
						onClick={submitHandler}
						disabled={cartItems.length === 0}
					>
                        Finalizar Compra
					</button>}
				</div>
			</Card.Footer>
		</Card>
	);
}
